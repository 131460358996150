<template lang="pug">
div
  div(
    v-if="$can('read', 'users')"
    id="div-with-loading-login"
    class="vs-con-loading__container"
  )
    ag-grid-table(
      animateRows=true
      suppressRowClickSelection=true
      :columnDefs="chartConfig.columnDefs"
      :rowData="chartConfig.rowData"
      :gridOptions="gridOptions"
      keyObject="users"
      rowSelection="multiple"
      @RefreshTableData="RefreshTableData"
    )
      template(
        v-if="isActionBar()"
        v-slot:actionBar
      )
        ag-action-bar(
          :is_add="$can('create', 'users')"
          :is_edit="$can('update', 'users')"
          :is_trash="false"
          :is_activate="$can('update', 'users')"
          :is_deactivate="$can('update', 'users')"
          :isClientModule="false"
          :is_import="false"
          :json_data="chartConfig.rowData"
          :json_fields="json_fields"
          @add="formCreate"
          @edit="onBtnEdit"
          @active="changeStatus(1)"
          @deactivate="changeStatus(2)"
          @import="popupImport=!popupImport"
        )
  vue-form(
    ref="FormMap"
    :popupActivo="popupActivo"
    :users="currentUsers"
    @refreshTable="getUsers"
    @closePopup="closePo"
  )
</template>
<script>
import ClientService from "@/api/clients/clients"//eslint-disable-line
import UsersServices from "@/api/users/Users"
import FieldsUser from "@/fields/users/fieldsUser"
import ErrorNotify from "@/components/errors/Errors"
import FormMap from "@/components/users/FormMap"
import AgGridTable from "@/components/tableAgGrid/Index"
import ActionsBar from "@/components/tableAgGrid/ActionsBar"
import { mapState } from "vuex"
import { translateTableFields } from "@/filter"

const NAME = "ListUsers"

export default {
  name: NAME,
  components: {
    "ag-grid-table": AgGridTable,
    "ag-action-bar": ActionsBar,
    "vue-form": FormMap
  },
  data() {
    return {
      json_data: [],
      json_fields: FieldsUser.json_fields,
      example: FieldsUser.example,
      dataExample: FieldsUser.data_example,
      fieldsCampos: FieldsUser.fields_label,
      urlUpload: FieldsUser.url_upload,
      Namefields: FieldsUser.name_fields,
      importer: FieldsUser.name_importer,
      popupImport: false,
      fields: FieldsUser.data,
      currentUsers: null,
      popupActivo: false,
      gridOptions: {
        localeText: { noRowsToShow: `${this.$t("show_not_data")}` }
      },
      chartConfig: {
        columnDefs: translateTableFields(FieldsUser.columnDefs, 'users', {
          profile: {
            valueGetter: this.setProfile
          },
          is_driver: {
            cellRenderer: this.setIsDriver
          },
          status: {
            cellRenderer: this.setIsActiveCell,
            valueGetter: this.setIsActiveVal
          },
          company: {
            cellRenderer: this.setCompanyCell,
            valueGetter: this.setCompany
          },
          action: {
            cellRenderer: "actionsusers"
          }
        }),
        rowData: []
      }
    }
  },
  mounted() {
    this.getUsers()
  },
  computed: {
    ...mapState("userService", ["userEdit", "userMap"])
  },
  watch: {
    userEdit(val) {
      if (val != null) {
        this.$router.push({
          name: "pageUsersEdit",
          params: { id: this.userEdit.id }
        })
      }
    },
    userMap(val) {
      if (val != null) {
        this.popupActivo = !this.popupActivo
        this.currentUsers = val
      }
    }
  },
  methods: {
    RefreshTableData() {
      this.chartConfig.columnDefs = []
      setTimeout(() => {
        this.chartConfig.columnDefs = translateTableFields(FieldsUser.columnDefs, 'users', {
          profile: {
            valueGetter: this.setProfile
          },
          status: {
            cellRenderer: this.setIsActiveCell,
            valueGetter: this.setIsActiveVal
          },
          company: {
            cellRenderer: this.setCompanyCell,
            valueGetter: this.setCompany
          },
          action: {
            cellRenderer: "actionsusers"
          }
        })
      }, 100)
    },
    setProfile (val) {
      if (val.data.profile.length > 0) {
        return val.data.profile[0]
      } else {
        return ''
      }
    },
    closePo(val) {
      if (val != null) {
        this.$refs.FormMap.clear()
      }
      this.popupActivo = false
      this.currentUsers = null
    },
    setIsActiveVal(val) {
      switch (val.data.status) {
        case 'Activado':
          return this.$t('vehicles.activado')
        case 'Desactivado':
          return this.$t('vehicles.desactivado')
        case 'Bloqueado':
          return this.$t('vehicles.bloqueado')
        case 'Eliminado':
          return this.$t('vehicles.eliminado')
        default:
          return this.$t('vehicles.sin_estado')
      }
    },
    setIsActiveCell(val) {
      switch (val.data.status) {
        case 'Activado':
          return '<div class="con-vs-chip con-color" style="color: rgba(255, 255, 255, 0.9);background: #3dc9b3;width: 9em;height: 2em;margin: 6px -21px;">' +'<span class="text-chip vs-chip--text">' + this.$t('vehicles.activado') + '</span> </div>'
        case 'Desactivado':
          return '<div class="con-vs-chip con-color" style="color: rgba(255, 255, 255, 0.9);background: #ff9f43;width: 9em;height: 2em;margin: 6px -21px;">' +'<span class="text-chip vs-chip--text">' + this.$t('vehicles.desactivado') + '</span> </div>'
        case 'Bloqueado':
          return '<div class="con-vs-chip con-color" style="color: rgba(255, 255, 255, 0.9);background: #e53e3e;width: 9em;height: 2em;margin: 6px -21px;">' +'<span class="text-chip vs-chip--text">' + this.$t('vehicles.bloqueado') + '</span> </div>'
        case 'Eliminado':
          return '<div class="con-vs-chip con-color" style="color: rgba(255, 255, 255, 0.9);background: #19048a;width: 9em;height: 2em;margin: 6px -21px;">' +'<span class="text-chip vs-chip--text">' + this.$t('vehicles.eliminado') + '</span> </div>'
        default:
          return '<div class="con-vs-chip con-color" style="color: #1a202c;background: #edf2f7;width: 9em;height: 2em;margin: 6px -21px;">' +'<span class="text-chip vs-chip--text">' + this.$t('vehicles.sin_estado') + '</span> </div>'
      }
    },
    setCompany (val) {
      if (val.data.company != null) {
        return val.data.company
      } 
      return this.$t('company.sin_company')
    },
    setCompanyCell(val) {
      if (val.data.company != null) {
        return val.data.company
      } else {
        return '<div class="con-vs-chip con-color" style="color: #1a202c;background: #edf2f7;width: 9em;height: 2em;margin: 6px -21px;">' +'<span class="text-chip vs-chip--text">' + this.$t('company.sin_company') + '</span> </div>'
      }
    },
    onFirstDataRendered() {
      setTimeout(() => {
        let allColumnIds = []
        this.gridOptions.columnApi.getAllColumns().forEach(column => {
          allColumnIds.push(column.colId)
        })
        this.gridOptions.columnApi.autoSizeColumns(allColumnIds)
      }, 100)
    },
    getUsers() {
      this.$vs.loading({
        container: "#div-with-loading-login",
        type: "material",
        scale: 1
      })
      UsersServices.getUsersData()
        .then(response => {
          this.chartConfig.rowData = response.data.data
          this.$vs.loading.close("#div-with-loading-login > .con-vs-loading")
        })
        .catch(() => {
          this.$vs.loading.close("#div-with-loading-login > .con-vs-loading")
        })
    },
    isActionBar() {
      if (
        this.$can("read", "users") ||
        this.$can("create", "users") ||
        this.$can("update", "users") ||
        this.$can("delete", "users")
      ) {
        return true
      } else {
        return false
      }
    },
    resultTable(val) {
      this.json_data = val.results
    },
    resultData(data) {
      if (data.data.errors.n_errors == 0) {
        this.popupImport = !this.popupImport
      }
    },
    formCreate() {
      this.$router.push({
        name: "pageUsersCreate"
      })
    },
    onBtnEdit() {
      let data = this.gridOptions.api.getSelectedNodes()
      if (data.length === 1) {
        this.$router.push({
          name: "pageUsersEdit",
          params: { id: data[0].data.id }
        })
      } else {
        this.$vs.notify({
          text: this.$t("vehicles.selecction"),
          color: "danger",
          iconPack: "feather",
          icon: "icon-x-circle"
        })
      }
    },
    changeStatus(val) {
      let data = this.gridOptions.api.getSelectedNodes()
      if (data.length == 0) {
        this.$vs.notify({
          text: this.$t("vehicles.selecction"),
          color: "danger",
          iconPack: "feather",
          icon: "icon-x-circle"
        })
      } else {
        let usersData = data.filter(
          user =>
            user.data.email !=
            JSON.parse(localStorage.getItem("userInfo")).email
        )
        let users = usersData.map(a => {
          return {
            id: a.data.id,
            is_active: val
          }
        })
        if (val) {
          this.$swal({
            position: "center",
            type: "info",
            title: this.$t("message_confir.activate_user"),
            confirmButtonColor: "#FDD402",
            cancelButtonColor: "#ea5455",
            showCancelButton: true,
            showConfirmButton: true
          }).then(result => {
            if (result.value) {
              this.updateState(users, val)
            }
          })
        } else {
          this.$swal({
            position: "center",
            type: "info",
            title: this.$t("message_confir.desactivate_user"),
            confirmButtonColor: "#FDD402",
            cancelButtonColor: "#ea5455",
            showCancelButton: true,
            showConfirmButton: true
          }).then(result => {
            if (result.value) {
              this.updateState(users, val)
            }
          })
        }
      }
    },
    updateState(users, val) {
      UsersServices.changeStateActivate({ users: users })
        .then(() => {
          this.getUsers()
          let number = users.length
          if (val) {
            this.$vs.notify({
              text: this.$tc("vehicle_response", number, {
                length: users.length,
                sujeto: this.$tc("users_activate", number),
                action: this.$tc("activar", number)
              }),
              color: "#64cc52",
              iconPack: "feather",
              icon: "icon-check-circle"
            })
          } else {
            this.$vs.notify({
              text: this.$tc("vehicle_response", number, {
                length: users.length,
                sujeto: this.$tc("users_activate", number),
                action: this.$tc("desactivar", number)
              }),
              color: "#64cc52",
              iconPack: "feather",
              icon: "icon-check-circle"
            })
          }
        })
        .catch(response => {
          ErrorNotify.formsErrors({
            data: response.response,
            ob: this
          })
        })
    }
  }
}
</script>