<template lang="pug">
div
  div(
    v-if="$can('read', 'news')"
    id="div-with-loading-news"
    class="vs-con-loading__container"
  )
    ag-grid-table(
      animateRows=true
      suppressRowClickSelection=true
      :columnDefs="chartConfig.columnDefs"
      :rowData="chartConfig.rowData"
      :gridOptions="gridOptions"
      :sizeTofit="true"
      rowSelection="single"
      @RefreshTableData="RefreshTableData"
    )
      template(
        v-if="isActionBar()"
        v-slot:actionBar
      )
        ag-action-bar(
          :is_add="$can('create', 'news')"
          :is_edit="$can('update', 'news')"
          :is_company="false"
          :is_activate="false"
          :is_deactivate="false"
          :is_exportable="false"
          :is_import="false"
          @add="modalCreateNews"
          @edit="onBtnEdit"
          @IsCompany="IsCompany"
        )
  vue-form(
    ref="FormNews"
    :popupActivo="popupActivo"
    :newness="currentNews"
    @refreshTable="getStatusAll"
    @closePopup="closePo"
  )
</template>
<script>
import { validatePermission } from "@/Ability"
import { mapState } from "vuex"
import news from "@/api/Newness/newness"
import NewsForm from "@/components/news/Form"
import AgGridTable from "@/components/tableAgGrid/Index"
import ActionsBar from "@/components/tableAgGrid/ActionsBar"
import FieldsNews from "@/fields/news/fieldsNews"
import { translateTableFields } from "@/filter"

const NAME = "ManageNews"

export default {
  name: NAME,
  components: {
    "vue-form": NewsForm,
    "ag-grid-table": AgGridTable,
    "ag-action-bar": ActionsBar
  },
  data() {
    return {
      popupActivo: false,
      currentNews: null,
      scopes: [],
      dataNews: null,
      gridOptions: {
        localeText: { noRowsToShow: `${this.$t("show_not_data")}` }
      },
      chartConfig: {
        columnDefs: translateTableFields(FieldsNews.columnDefs),
        rowData: []
      }
    }
  },
  computed: {
    ...mapState("news", ["newsEdit", "newsDelete"])
  },
  watch: {
  },
  methods: {
    isActionBar() {
      if (
        this.$can("read", "news") ||
        this.$can("create", "news") ||
        this.$can("update", "news") ||
        this.$can("delete", "news")
      ) {
        return true
      } else {
        return false
      }
    },
    RefreshTableData() {
      this.asignColumnDefs()
    },
    asignColumnDefs() {
      this.chartConfig.columnDefs = []
      setTimeout(() => {
        this.chartConfig.columnDefs = translateTableFields(FieldsNews.columnDefs)
      }, 50)
    },
    IsCompany() {
      let data = this.gridOptions.api.getSelectedNodes()
      if (data.length > 0) {
        if (data[0].data.id == null) {
          this.$vs.notify({
            text: this.$t("status.nonEditableStatus"),
            color: "danger",
            iconPack: "feather",
            icon: "icon-x-circle"
          })
        } else {
          this.dataNews = data
        }
      } else {
        this.$vs.notify({
          text: this.$t("vehicles.selecction"),
          color: "danger",
          iconPack: "feather",
          icon: "icon-x-circle"
        })
      }
    },
    onBtnEdit() {
      let data = this.gridOptions.api.getSelectedNodes()
      if (data.length > 0) {
        if (data[0].data.id == null) {
          this.$vs.notify({
            text: this.$t("status.nonEditableStatus"),
            color: "danger",
            iconPack: "feather",
            icon: "icon-x-circle"
          })
        } else {
          this.popupActivo = true
          this.currentNews = data[0].data
        }
      } else {
        this.$vs.notify({
          text: this.$t("vehicles.selecction"),
          color: "danger",
          iconPack: "feather",
          icon: "icon-x-circle"
        })
      }
    },
    closePo(val) {
      if (val != null) {
        this.$refs.FormNews.clear()
      }
      this.popupActivo = false
      this.currentNews = null
    },
    modalCreateNews() {
      this.popupActivo = true
      this.currentNews = null
    },
    getStatusAll() {
      this.$vs.loading({
        container: "#div-with-loading-news",
        type: "material",
        scale: 1
      })
      news
        .getNewnessList()
        .then(response => {
          this.chartConfig.rowData = response.data.message.newnesses
          // setTimeout(() => {
          //   let allColumnIds = []
          //   this.gridOptions.columnApi.getAllColumns().forEach(column => {
          //     allColumnIds.push(column.colId)
          //   })
          //   this.gridOptions.columnApi.autoSizeColumns(allColumnIds)
          // }, 50)
        })
        .finally(() => {
          this.$vs.loading.close("#div-with-loading-news > .con-vs-loading")
        })
    },
  },
  mounted() {
    this.getStatusAll()
  },
  created() {
    validatePermission(this, "read", "news", "Home")
  }
}
</script>