import HTTP from '@/http-common'

export default {
  getNewnessList() {
    return HTTP.get('/newness/')
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  getNewnesScopes() {
    return HTTP.get('/newness/scopes')
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  newnessCreate(data) {
    return HTTP.post('/newness/types/create', data)
  },
  newnessTypesList() {
    return HTTP.get()
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  newnessTypesCreate(newness_types_create = "/newness/types/create") {
    let json_newness_types_create = {
      "type": "string"
    }
    return HTTP.post(newness_types_create, json_newness_types_create)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  newnessTypesDelete(id) {
    HTTP.setHeader(true)
    let request = [
      `newness/types/delete/${id}`
    ]
    return new Promise((s, r) => {
      HTTP.delete(...request).then(s, r).then(() => { return }, () => { return })
    })
  },
  newnessTypeUpdate(data) {

    HTTP.setHeader(true)
    let request = [
      `newness/types/update/${data.id}`,
      data
    ]
    return new Promise((s, r) => {
      HTTP.put(...request).then(s, r).then(() => { return }, () => { return })
    })
  }


}