<template lang="pug">
vs-popup(
  id="popupStatusForm"
  classContent="popup-example"
  :title="$t('form-map.form')"
  :active.sync="popup"
)
  div
    div(
      class="vx-row"
    )
      vs-divider
      vs-list
        vs-list-item(
          title="Generar token de acceso"
        )
          vs-button.block.offset-3(
            color="primary"
            type="filled"
            :disabled="isSaved"
            @click="setToken"
          ) {{$t('form-map.generar')}}
      vs-divider
      vs-list
        vs-list-item(
          :title="$t('sp.link_cliente')"
        )
          div(
            class="container"
          )
            vs-button.block.offset-3(
              color="primary"
              type="filled"
              @click="doCopy"
            ) Obtener url!
      vs-divider
    h3 Vista previa
    <iframe v-if="urlToken != null" width="550" height="300" :src="urlToken" title=""></iframe>
  vs-row
    vs-col(
      vs-type="flex"
      vs-justify="center"
      vs-align="center"
      vs-lg="12"
      vs-sm="12"
      vs-xs="12"
    )
      vs-button.mt-6.block.offset-3(
        color="primary"
        type="filled"
        @click="setSave"
      ) {{$t('form-map.save')}}
</template>

<script>
import HTTP from "@/http-common"
import i18n from "@/i18n.js"
import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'

VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)

export default {
  props: {
    users: {
      type: Object,
      required: false
    },
    popupActivo: {
      type: Boolean,
      required: false
    },
  },
  data() {
    return {
      i18n: i18n,
      user: null,
      token: "",
      urlToken: 'https://seguimiento.smartquick.com.co/',
      urlTokenCall: 'https://seguimiento.smartquick.com.co/',
      isSaved: false,
      message: 'Copy These Text'
    }
  },
  computed: {
    popup: {
      get() {
        return this.popupActivo
      },
      set(val) {
        if (!val) {
          this.$emit("closePopup", this.user)
          this.$store.dispatch("userService/set_user_map", null)
        }
      }
    },
  },
  watch: {
    users(val) {
      if (val != null) {
        this.user = val
        this.getTokens()
      }
    }
  },
  methods: {
    doCopy: function () {
      this.$copyText(this.urlToken).then(function (e) {
        alert('Url copiada')
        console.log(e)
      }, function (e) {
        alert('Can not copy')
        console.log(e)
      })
    },
    getTokens() {
      let url = `v1/users/gettokens/${this.users.id}`
      HTTP.setHeader(true)
      HTTP.get(url)
        .then((response) => {
          if (Object.keys(response.data).length > 0) {
            this.token=response.data.data.token
            this.urlToken = `https://seguimiento.smartquick.com.co/search/?token=${this.token}`
            this.urlTokenCall = `https://seguimiento.smartquick.com.co/seguimiento/call?token=${this.token}`
            this.isSaved = true
          } else {
            this.isSaved = false
          }
        })
    },
    setToken() {
      this.urlToken = null
      let url = `v1/users/gettoken/${this.users.id}`
      HTTP.setHeader(true)
      HTTP.get(url)
        .then((response) => {
         if (Object.keys(response.data).length > 0) {
            this.token=response.data.data.token
            this.urlToken = `https://seguimiento.smartquick.com.co/search/?token=${this.token}`
            this.urlTokenCall = `https://seguimiento.smartquick.com.co/search/call?token=${this.token}`
            this.isSaved = true
         } else {
           this.isSaved = false
         }
        })
    },
    clear() {
      this.user = null
      this.urlToken = 'https://seguimiento.smartquick.com.co/'
      this.urlTokenCall = 'https://seguimiento.smartquick.com.co/'
      this.token = ""
    },
    setSave() {
      let url = 'sendemail'
      let dataFormated = {
        user: this.user,
        url: this.urlToken,
        urlCall: this.urlTokenCall
      }
      HTTP.setHeader(true)
      HTTP.post(url, dataFormated)
        .then(() => {
          this.$vs.notify({
            text: this.$t("email enviado correctamente"),
            color: "#64cc52",
            iconPack: "feather",
            icon: "icon-check-circle"
          })
        })
    }
  },
  beforeDestroy() {
    var elem = document.querySelector("#popupStatusForm")
    if (elem) {
      elem.parentNode.removeChild(elem)
    }
  }
}
</script>

<style>
.toggle-container {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
