<template lang="pug">
  vue-index
</template>


<script>

import MangeNews from "@/components/news/Index"

const NAME = "MangeNews"

export default {
  name: NAME,
  components: {
    "vue-index": MangeNews
  }
}
</script>