import i18n from "@/i18n.js"

const columnDefs = [
  {
    headerName: `${i18n.t('users.first_name')}`,
    traslateName: "users.first_name",
    field: "first_name",
    sortable: true,
    filter: true,
    hide: false,
    showKey: true,
    checkboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    headerCheckboxSelection: true,
    filterParams: {
      clearButton: true
    }
  },
  {
    headerName: `${i18n.t('users.email')}`,
    traslateName: "users.email",
    field: "email",
    sortable: true,
    filter: true,
    hide: false,
    showKey: true,
    checkboxSelection: false,
    headerCheckboxSelectionFilteredOnly: false,
    headerCheckboxSelection: false,
    filterParams: {
      clearButton: true
    }
  },
  {
    headerName: `${i18n.t('users.roles')}`,
    traslateName: "users.roles",
    field: "profile",
    sortable: true,
    filter: true,
    hide: false,
    showKey: true,
    checkboxSelection: false,
    headerCheckboxSelectionFilteredOnly: false,
    headerCheckboxSelection: false,
    filterParams: {
      clearButton: true
    },
    width: 100
  },
  {
    headerName: `${i18n.t('indicators.status')}`,
    traslateName: "indicators.status",
    field: "status",
    sortable: true,
    filter: true,
    hide: false,
    showKey: true,
    checkboxSelection: false,
    headerCheckboxSelectionFilteredOnly: false,
    headerCheckboxSelection: false,
    filterParams: {
      clearButton: true
    }
  },
  {
    headerName: `${i18n.t('users.company')}`,
    traslateName: "users.company",
    field: "company",
    sortable: true,
    filter: true,
    hide: false,
    showKey: true,
    checkboxSelection: false,
    headerCheckboxSelectionFilteredOnly: false,
    headerCheckboxSelection: false,
    filterParams: {
      clearButton: true
    }
  },
  {
    headerName: `${i18n.t('servicios_tab.actions')}`,
    traslateName: "servicios_tab.actions",
    sortable: false,
    filter: false,
    hide: false,
    showKey: true,
    checkboxSelection: false,
    headerCheckboxSelectionFilteredOnly: false,
    headerCheckboxSelection: false,
    filterParams: {
      clearButton: false
    },
    cellRenderer: "actionsusers"
  }
]

const name_importer = "importerUser"
const name_fields = [
  {
    headerName: `${i18n.t('importerUser.first_name')}`,
    traslateName: "importerUser.first_name",
    field: "first_name",
    name: "first_name",
    sortable: true,
    filter: false,
    hide: false,
    showKey: true,
    checkboxSelection: false,
    headerCheckboxSelectionFilteredOnly: false,
    headerCheckboxSelection: false,
    filterParams: {
      clearButton: false
    }
  },
  {
    headerName: `${i18n.t('importerUser.last_name')}`,
    traslateName: "importerUser.last_name",
    field: "last_name",
    name: "last_name",
    sortable: true,
    filter: false,
    hide: false,
    showKey: true,
    checkboxSelection: false,
    headerCheckboxSelectionFilteredOnly: false,
    headerCheckboxSelection: false,
    filterParams: {
      clearButton: false
    }
  },
  {
    headerName: `${i18n.t('importerUser.document_type')}`,
    traslateName: "importerUser.document_type",
    field: "document_type",
    name: "document_type",
    sortable: true,
    filter: false,
    hide: false,
    showKey: true,
    checkboxSelection: false,
    headerCheckboxSelectionFilteredOnly: false,
    headerCheckboxSelection: false,
    filterParams: {
      clearButton: false
    }
  },
  {
    headerName: `${i18n.t('importerUser.document')}`,
    traslateName: "importerUser.document",
    field: "document",
    name: "document",
    sortable: true,
    filter: false,
    hide: false,
    showKey: true,
    checkboxSelection: false,
    headerCheckboxSelectionFilteredOnly: false,
    headerCheckboxSelection: false,
    filterParams: {
      clearButton: false
    }
  },
  {
    headerName: `${i18n.t('importerUser.email')}`,
    traslateName: "importerUser.email",
    field: "email",
    name: "email",
    sortable: true,
    filter: false,
    hide: false,
    showKey: true,
    checkboxSelection: false,
    headerCheckboxSelectionFilteredOnly: false,
    headerCheckboxSelection: false,
    filterParams: {
      clearButton: false
    }
  },
  {
    headerName: `${i18n.t('importerUser.mobile_phone')}`,
    traslateName: "importerUser.mobile_phone",
    field: "mobile_phone",
    name: "mobile_phone",
    sortable: true,
    filter: false,
    hide: false,
    showKey: true,
    checkboxSelection: false,
    headerCheckboxSelectionFilteredOnly: false,
    headerCheckboxSelection: false,
    filterParams: {
      clearButton: false
    }
  },
  {
    headerName: `${i18n.t('importerUser.landline_phone')}`,
    traslateName: "importerUser.landline_phone",
    field: "landline_phone",
    name: "landline_phone",
    sortable: true,
    filter: false,
    hide: false,
    showKey: true,
    checkboxSelection: false,
    headerCheckboxSelectionFilteredOnly: false,
    headerCheckboxSelection: false,
    filterParams: {
      clearButton: false
    }
  },
  {
    headerName: `${i18n.t('importerUser.gender')}`,
    traslateName: "importerUser.gender",
    field: "gender",
    name: "gender",
    sortable: true,
    filter: false,
    hide: false,
    showKey: true,
    checkboxSelection: false,
    headerCheckboxSelectionFilteredOnly: false,
    headerCheckboxSelection: false,
    filterParams: {
      clearButton: false
    }
  },
  {
    headerName: `${i18n.t('importerUser.is_driver')}`,
    traslateName: "importerUser.is_driver",
    field: "is_driver",
    name: "is_driver",
    sortable: true,
    filter: false,
    hide: false,
    showKey: true,
    checkboxSelection: false,
    headerCheckboxSelectionFilteredOnly: false,
    headerCheckboxSelection: false,
    filterParams: {
      clearButton: false
    }
  }
]
const fields_label = [
  {
    key: 0,
    name: "first_name"
  },
  {
    key: 1,
    name: "last_name"
  },
  {
    key: 2,
    name: "email"
  },
  {
    key: 3,
    name: "document_type"
  },
  {
    key: 4,
    name: "document"
  },
  {
    key: 5,
    name: "gender"
  },
  {
    key: 6,
    name: "is_driver"
  },
  {
    key: 7,
    name: "landline_phone"
  },
  {
    key: 8,
    name: "mobile_phone"
  }
]
const data_example = [
  {
    first_name: 'Nombre del usuario',
    last_name: 'Apellido del usuario',
    email: 'Email del usuario',
    document_type: '0 = Cédula de Ciudadanía, 1 = Cédula de Extranjería, 2 = Pasaporte, 3 = Nit',
    document: 'Número de documento de identidad',
    gender: '0 = Masculino, 1 = Femenino',
    is_driver: '1 = Activo o  0 = Desactivado',
    landline_phone: 'Número de tlf debe contener minino 7 y maximo 15',
    mobile_phone: 'Número de tlf debe contener minino 7 y maximo 15'
  }
]
const example = {
  'first_name': 'first_name',
  'last_name': 'last_name',
  'email': 'email',
  'document_type': 'document_type',
  'document': 'document',
  'gender': 'gender',
  'is_driver': 'is_driver',
  'landline_phone': 'landline_phone',
  'mobile_phone': 'mobile_phone'
}

const url_upload = `/users/create/multiple`

const json_fields = {
  'iD': 'id',
  'Cedula': 'document',
  'email': 'email',
  'Nombres': 'user_name',
  'Es Conductor': {
    field: 'is_active',
    callback: (value) => {
      if (value) {
        return `Si`
      } else {
        return `No`
      }
    }
  },
  'Activo': {
    field: 'is_active',
    callback: (value) => {
      if (value) {
        return `Si`
      } else {
        return `No`
      }
    }
  },
  'Tlf': 'mobile_phone'
}

export default {
  columnDefs,
  name_importer,
  name_fields,
  fields_label,
  data_example,
  example,
  url_upload,
  json_fields
}