<template>
  <vs-popup
    id="popupNewsForm"
    classContent="popup-example"
    :title="$t('manage_news.news')"
    :active.sync="popup"
  >
    <div>
        <vs-input
          class="w-full inputx sm-input"
          name="name"
          :label-placeholder="$t('manage_news.novelty')"
          v-model="name"
        ></vs-input
        ><span class="text-danger text-sm" v-if="errors.has('name')">{{
          errors.first("name")
        }}</span>
    </div>
    <div>
        <label class="vs-select--label">{{ $t('company.version') }}</label>
          <v-select
            :options="versiones"
            label="name"
            :clearable="false"
            name="version"
            :data-vv-as="$t('company.version')"
            v-validate="'required'"
            v-model="version_data"
            :placeholder="$t('company.version')"
            autocomplete="off"
          >
            <template slot="option" slot-scope="option">{{ option.name | capitalize }}</template>
          </v-select>
          <span
            class="text-danger text-sm"
            v-show="errors.has('version')"
          >{{ errors.first('version') }}</span>
    </div>
    <div class="grid place-content-center">
      <vs-button class="mt-6 block offset-3"
        color="primary"
        type="filled"
        :disabled="isSaved"
        @click="setSave">
        {{$t('manage_news.save')}}
      </vs-button>
    </div>
  </vs-popup>
</template>

<script>
import HTTP from "@/http-common";
import ErrorNotify from "@/components/errors/Errors";
import i18n from "@/i18n.js";
export default {
  props: {
    newness: {
      type: Object,
      required: false,
    },
    popupActivo: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      i18n: i18n,
      name: "",
      version_data: "",
      isSaved: false,
      versiones: [],
      version_id: null,
      company:{},
    };
  },
  computed: {
    popup: {
      get() {
        return this.popupActivo
      },
      set(val) {
        if (!val) {
          this.$emit('closePopup', this.newness)
          this.$store.dispatch('newnessServices/set_newness', null)
        }
      }
    },
  },
  watch: {
    newness(val) {
      this.clear();
      if (val != null) {
        this.name = val.name;
        this.version_id = val.id;
        this.version_data = val.version
      }
    },
  },
  methods: {
    clear() {
      this.name = "";
      this.version_data = "";
      this.$validator.reset();
    },
    setSave() {
      this.isSaved = true;
      let dataFormated = {
        name: this.name,
        version: this.version_data.id ? this.version_data.name : this.version_data,
        company_id : this.company.id
      };
      if (this.newness == null) {
        this.createNewness(dataFormated);
      } else {
        this.updateNewness(dataFormated);
      }
    },
    updateNewness(dataFormated) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let url = `newness/update/${this.newness.id}`;
          HTTP.setHeader(true);
          HTTP.put(url, dataFormated)
            .then(() => {
              this.$vs.notify({
                text: this.$t("company.update"),
                color: "#64cc52",
                iconPack: "feather",
                icon: "icon-check-circle",
              });
              this.popup = false;
              this.$emit("refreshTable");
              this.isSaved = false;
              this.clear();
            })
            .catch((response) => {
              ErrorNotify.formsErrors({
                data: response.response,
                ob: this,
              });
              this.isSaved = false;
            });
        } else {
          this.isSaved = false;
        }
      });
    },
    createNewness(dataFormated) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let url = `/newness/create`;
          HTTP.setHeader(true);
          HTTP.post(url, dataFormated)
            .then(() => {
              this.$vs.notify({
                text: this.$t("company.create"),
                color: "#64cc52",
                iconPack: "feather",
                icon: "icon-check-circle",
              });
              this.popup = false;
              this.$emit("refreshTable");
              this.isSaved = false;
              this.clear();
            })
            .catch((response) => {console.log(response);
              ErrorNotify.formsErrors({
                data: response.response,
                ob: this,
              });
              this.isSaved = false;
            });
        } else {
          this.isSaved = false;
        }
      });
    },
    async getCompany() {
      try {
        const {data}  = await HTTP.get("/v1/companies/companies/");
        this.company = data.map((row) => ({
          id: row.id,
          name: row.company,
          version: row.version,
        }));
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted () {
    this.getCompany()
  },
  created() {
    this.versiones.push(
      {
        id: 20,
        name: "V3",
      },
      {
        id: 21,
        name: "V4",
      },
      {
        id: 22,
        name: "V1",
      },
    );
  },
  beforeDestroy() {
    var elem = document.querySelector("#popupNewsForm");
    if (elem) {
      elem.parentNode.removeChild(elem);
    }
  },
};
</script>

<style>
.toggle-container {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
