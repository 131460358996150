import i18n from "@/i18n.js"

const columnDefs = [
  {
    headerName: `${i18n.t('manage_news.novelty')}`,
    traslateName: "manage_news.novelty",
    field: "name",
    sortable: true,
    filter: true,
    hide: false,
    showKey: true,
    checkboxSelection: true,
    filterParams: {
      clearButton: true
    }
  },
  {
    headerName: `${i18n.t('manage_news.code')}`,
    traslateName: "manage_news.version",
    field: "version",
    sortable: true,
    filter: true,
    hide: false,
    showKey: true,
    filterParams: {
      clearButton: true
    }
  }
]
export default {
  columnDefs
}