<template lang="pug">
  vue-index
</template>
<script>

import UserIndex from "@/components/users/Index"
import {validatePermission} from '@/Ability'

const NAME="UserViewIndex"

export default {
  name: NAME,
  components: {
    "vue-index": UserIndex
  },
  created(){
    validatePermission(this,'read','users','Home')
  }
}
</script>
